.Projects {
  width: 100%;
  height: 100%px;
  padding: 20px;
}
.Projects h3 {
  text-align: center;
  font-size: 50px;
  margin-top: 20px;
  font-family: var(--head-font);
}
.Projects .project {
  font-family: var(--body-font);
  margin: 20px 0;
}
.Projects h6 {
  font-size: 18px;
  font-weight: 900;
}
.dark-mode .card {
  background-color: rgb(164, 164, 164);
  color: rgb(255, 255, 255);
}
@media (max-width: 768px) {
  .Projects h3 {
    font-size: 30px;
  }
  .Projects h6 {
    font-size: 16px;
  }
  .Projects p {
    font-size: 14px;
  }
}
