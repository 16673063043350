.CV {
  text-align: center;
  height: 100vh;
}
.CV a {
  font-family: var(--head-font);
  font-size: 150px;
  font-weight: 900;
  text-decoration: none;
  color: Black;
  text-shadow: -5px 5px #ef3550, -10px 10px #f48fb1, -15px 15px #7e57c2,
    -20px 20px #2196f3, -25px 25px #26c6da, -30px 30px #43a047,
    -35px 35px #eeff41, -40px 40px #f9a825, -45px 45px #ff5722;
}
.dark-mode .CV a {
  color: white;
}
.CV h4 {
  font-family: var(--body-font);
  font-size: 35px;
  margin-top: 30px;
}
.CV object {
  margin: 20px;
  height: 200px;
  width: 200px;
}
.dark-mode .CV object {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 30%;
}
.CV .second {
  margin: 0 auto;
}
@media (max-width: 768px) {
  .CV {
    height: 80vh;
  }
  .CV a {
    font-size: 80px;
    text-shadow: -3px 3px #ef3550, -6px 6px #f48fb1, -9px 9px #7e57c2,
      -12px 12px #2196f3, -15px 15px #26c6da, -18px 18px #43a047,
      -21px 21px #eeff41, -24px 24px #f9a825, -27px 27px #ff5722;
  }
  .CV h4 {
    font-size: 20px;
  }
  .CV object {
    width: 150px;
    height: 150px;
  }
}
