:root {
  --primary-light: rgb(239, 236, 236);
  --secondary-light: rgb(98, 146, 154);
  --primary-dark: rgb(54, 52, 52);
  --secondary-dark: rgb(92, 87, 87);
  --head-font: "Audiowide", cursive;
  --body-font: "Play", sans-serif;
}

.OperaHero,
.Projects,
.CV,
.Contacts {
  background-color: var(--primary-light);
  color: rgb(0, 0, 0);
}
nav {
  background-color: var(--secondary-light);
}
.dark-mode .OperaHero,
.dark-mode .Projects,
.dark-mode .CV,
.dark-mode .Contacts {
  background-color: var(--primary-dark);
  color: rgb(255, 255, 255);
}
.dark-mode nav {
  background-color: var(--secondary-dark);
}
.dark-mode Link {
  color: rgb(240, 248, 255);
}
