.Contacts {
  font-family: var(--body-font);
  font-size: 18px;
  height: 100vh;
  text-align: left;
  background-size: cover;
}
.Contacts #email-text,
.Contacts #message-text {
  font-size: 25px;
}
.Contacts .section {
  margin: 0 auto;
  max-width: 800px;
  padding: 10px;
}
.Contacts #message {
  height: 200px;
  max-height: 260px;
}
.Contacts button {
  width: 100px;
}
.Contacts .contact-me {
  margin: 0 auto;
  padding-top: 20px;
}
.Contacts object {
  margin-top: 20px;
  height: 200px;
  background-color: rgba(92, 87, 87, 0.3);
  border-radius: 15px;
}
.dark-mode object {
  background-color: rgba(255, 255, 255, 0.2);
}
.Contacts h4 {
  font-family: var(--head-font);
  text-align: center;
  margin-top: 30px;
  font-size: 30px;
}
.Contacts button {
  background-color: var(--secondary-light);
  color: rgb(255, 255, 255);
}
.dark-mode .Contacts button {
  background-color: var(--secondary-dark);
  color: rgb(255, 255, 0);
}
@media (max-width: 768px) {
  .Contacts {
    font-size: 14px;
  }
  .Contacts object {
    height: 100px;
  }
  .Contacts h4 {
    font-size: 25px;
  }
  .Contacts #email-text,
  .Contacts #message-text {
    font-size: 16px;
  }
}
