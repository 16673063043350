.App .Navbar ul {
  padding: 0;
  margin: 0;
}
.App .Navbar li {
  font-family: var(--head-font);
  list-style: none;
  line-height: 50px;
  font-size: 25px;
  margin-left: 10px;
  margin-right: 10px;
}
.App .Navbar img {
  width: 50px;
  margin-left: 10px;
}

.App .Navbar a {
  text-decoration: none;
  color: black;
}
@media (max-width: 768px) {
  .App .Navbar img {
    width: 30px;
  }
  .App .Navbar li {
    font-size: 10px;
  }
}
