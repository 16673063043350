.Footer {
  background-color: var(--secondary-light);
  padding: 30px;
  font-family: var(--body-font);
  font-weight: 500;
  color: rgb(0, 0, 0);
}
.dark-mode .Footer {
  background-color: var(--secondary-dark);
  color: rgb(255, 255, 255);
}
.Footer .second {
  text-align: right;
}

.Footer ul {
  margin: 0;
  padding: 0;
}
.Footer .first li {
  list-style: none;
  line-height: 30px;
  font-size: 14px;
}
.Footer h6 {
  font-size: 20px;
  text-decoration: underline;
}
.Footer a {
  text-decoration: none;
  color: rgb(255, 255, 0);
}
.dark-mode .Footer a {
  color: rgb(255, 0, 0);
}
.Footer .second li {
  list-style: none;
  line-height: 30px;
  font-size: 30px;
  display: inline-block;
  margin: 10px 0 10px 40px;
}
@media (max-width: 768px) {
  .Footer h6 {
    font-size: 16px;
  }
  .Footer .first li,
  .Footer .second li {
    font-size: 14px;
  }
}
