.OperaHero {
  text-align: center;
  font-family: var(--body-font);
  width: 100%;
  background-size: cover;
}

.OperaHero header {
  display: flex;
  align-items: center;
}
.OperaHero h1 {
  text-align: left;
  padding-top: 100px;
  margin-left: 150px;
}
.OperaHero h2 {
  text-align: left;
  margin-left: 80px;
}
.OperaHero img {
  width: 500px;
  clip-path: ellipse(50% 34% at 50% 50%);
}
.OperaHero .name {
  font-family: var(--head-font);
  font-size: 100px;
  text-shadow: -8px 8px 2px var(--secondary-light);
}
.dark-mode .OperaHero .name {
  text-shadow: -8px 8px 2px var(--secondary-dark);
}
.OperaHero .hello {
  font-size: 60px;
}
@media (max-width: 768px) {
  .OperaHero h1 {
    padding-top: 50px;
    margin: 0;
  }
  .OperaHero img {
    width: 150px;
  }
  .OperaHero {
    font-size: 16px;
  }
  .OperaHero .name {
    font-size: 40px;
    text-shadow: -4px 4px 1px var(--secondary-light);
  }
  .OperaHero h2 {
    margin: 0;
  }
  .OperaHero .hello {
    font-size: 30px;
  }
}
